import React from "react"
import BlogItem from "../../BlogItem/BlogItem"
import Section from "../../Section/Section"

import "./Blog.scss"

export default function Blog({
  title,
  author,
  date,
  summary,
  image,
  imageAlt,
  slug,
}) {
  return (
    <div className="blog-section">
      <Section>
        <BlogItem
          title={title}
          author={author}
          date={date}
          summary={summary}
          slug={slug}
          imageAlt={imageAlt}
          image={image}
          isLastItem
          isOnLandingPage
        />
      </Section>
    </div>
  )
}
